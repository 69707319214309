import React, { useState } from "react";
import request from "../api/request";
import ContactModel from "../models/Contact";

export interface ContactProps {}

export const Contact: React.FC<ContactProps> = ({}) => {
  const emptyContact: ContactModel = {
    Name: "",
    Email: "",
    Phone: "",
    Company: "",
    Query: "",
    Question: "",
  };

  const [newContact, setNewContact] = useState<ContactModel>(emptyContact);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  const createContact = async () => {
    if (loading) return;
    const validationErrors = [];
    if (newContact.Name === "") validationErrors.push("Full name is required.");
    if (newContact.Email === "" && newContact.Phone === "")
      validationErrors.push("Either email or phone number is required.");
    if (newContact.Query === "")
      validationErrors.push(
        "Please specify your query or what you need help with."
      );
    if (validationErrors.length > 0) {
      setMessages(validationErrors);
      return;
    }
    const [resp, error] = await request(
      "contacts",
      "POST",
      newContact,
      setLoading,
      process.env.REACT_APP_CONTACT_URL
    );
    if (error) {
      setMessages(["Unexpected error while submitting."]);
      return;
    }
    setMessages(["Successfully submitted contact form!"]);
    setNewContact(emptyContact);
  };

  const inputs = [
    { key: "Name", label: "Full name*" },
    { key: "Email", label: "Email" },
    { key: "Phone", label: "Phone number" },
    { key: "Company", label: "Company" },
    { key: "Query", label: "What do you need help with?*" },
    { key: "Question", label: "How did you hear about us?" },
  ];

  const loadingRender = () => (
    <svg className="animate-spin h-5 w-5 mr-3 opacity-95" viewBox="0 0 24 24">
      <circle
        className="opacity-0"
        cx="12"
        cy="12"
        r="10"
        stroke="white"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="white"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );

  return (
    <div className="flex flex-col items-start">
      <div className="font-['SF-UI-Display'] text-4xl mb-10 text-left">
        Contact us
      </div>
      <div className="w-full font-['SF-UI-Text']">
        {inputs.map((i) => (
          <div key={i.key} className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                htmlFor="inline-full-name"
              >
                {i.label}
              </label>
            </div>
            <div className="w-full">
              {["Query", "Question"].includes(i.key) ? (
                <textarea
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cyan-500"
                  value={newContact[i.key as keyof ContactModel]}
                  onChange={(e) =>
                    setNewContact({ ...newContact, [i.key]: e.target.value })
                  }
                />
              ) : (
                <input
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cyan-500"
                  type="text"
                  value={newContact[i.key as keyof ContactModel]}
                  onChange={(e) =>
                    setNewContact({ ...newContact, [i.key]: e.target.value })
                  }
                />
              )}
            </div>
          </div>
        ))}
        <div className="mb-3">
          {messages.map((message) => (
            <div key={message} className="text-sm opacity-50">
              {message}
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <div className="">
            <button
              className="shadow bg-cyan-500 hover:bg-cyan-400 focus:shadow-outline focus:outline-none text-white font-bold py-1.5 px-3 rounded"
              type="button"
              onClick={() => createContact()}
              disabled={loading}
            >
              <div className="flex">
                {loading && loadingRender()}
                Submit
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
