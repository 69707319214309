import axios, { Method } from "axios";

export default async function request(
  endpoint: string,
  method: Method,
  data?: object,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  url?: string
): Promise<[any, string]> {
  if (setLoading) {
    setLoading(true);
  }
  const u = url ?? `${process.env.REACT_APP_CMS_URL ?? ""}/api/${endpoint}`;
  try {
    const resp = await axios(u, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
    return [resp, ""];
  } catch (e: any) {
    console.log(e);
    return [null, "Unexpected error occured."];
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
}
